<template lang="pug">
.navbar.navbar-expand-md.navbar-light.bg-transparent#navbar-is-home-switch
    .container-fluid
        router-link.navbar-brand(
            to="/"
            @click="collapseNavbar()"
        )
            img.logo-operandum(
                src="../public/logo_comp_transp.png"
                alt="Operandum logo"
            )
        button.navbar-toggler#navbar-toggler(
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbar-target'
        )
            span.navbar-toggler-icon
        .collapse.navbar-collapse#navbar-target
            ul.navbar-nav.mb-2.mb-lg-0
                li.nav-item.mx-2
                    router-link.nav-link.navbar-link-fix(
                        to="/vacatures"
                        @click="collapseNavbar()"
                    )
                        h4.fw-bold Vacatures
                li.nav-item.mx-2
                    router-link.nav-link.navbar-link-fix(
                        to="/over"
                        @click="collapseNavbar()"
                    )
                        h4.fw-bold Over
router-view
footer.footer
    .container-fluid.bg-secondary.bg-gradient
        .row
            .ps-5.pt-5.pb-3.col-sm-4.col-xl-2.offset-xl-3
                p
                    strong  Contact
                a.link-dark(
                    href="mailto:info@operandum.nl"
                )
                    p.email info@operandum.nl
                p.pt-2.px-5.smartphone 0683 985252
            .ps-5.pt-5.pb-3.col-sm-4.col-xl-2
                p
                    strong Bedrijfsgegevens
                p Operandum Werving & Selectie
                p.pt-2 KvK 56380259
            .ps-5.pt-5.pb-3.col-sm-4.col-xl-2
                p
                    strong Social media
                div.d-flex.justify-content-left
                    div.me-4
                        a(href="https://www.linkedin.com/in/berendvries/")
                            div.linkedin
                    div.me-4
                        a(href="https://www.facebook.com/Operandum-100860859115195/")
                            div.facebook
        .row.px-5.py-5.me-md-5.text-end
            p www.operandum.nl • © {{ dateFormatted }}
            //- p www.operandum.nl • © {{ dateFormatted }} • KvK nr: 56380259
</template>

<script>
import { monthNames } from './lib'

export default {
    name  : 'App',
    props : [],
    data () {
        const date = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
        const month = monthNames[date.getMonth()].slice(0, 3)
        const dateFormatted = `${month} ${date.getFullYear()}`

        return {
            dateFormatted,
            navBarInitialised : false,
            isHome            : null
        }
    },
    methods : {
        collapseNavbar () {
            const el = document.getElementById('navbar-toggler')
            // ! hack to run .click() only when collapsed, otherwise produces ugly artifacts
            const collapsed = Boolean(document.getElementsByClassName('navbar-collapse collapse show').length)
            if (el && collapsed) el.click()
        },
        setIsHome (isHome) {
            const homeAddition = ' fixed-top'
            const notHomeAddition = ' slight-box-shadow'
            const el = document.getElementById('navbar-is-home-switch')

            if (!this.navBarInitialised) {
                if (isHome && !window.isMobile) {
                    el.className += homeAddition
                } else {
                    el.className += notHomeAddition
                }
                this.navBarInitialised = true
                this.isHome = isHome
                return
            }

            if (this.isHome === isHome) return
            this.isHome = isHome

            if (isHome && !window.isMobile) {
                el.className = el.className.replace(notHomeAddition, homeAddition)
            } else {
                el.className = el.className.replace(homeAddition, notHomeAddition)
            }
        }
    }
}
</script>

<style lang="scss">
@import "styles/custom_bootstrap.scss";
@import "styles/global.scss";

.slight-box-shadow {
    box-shadow: 0 .1rem .6rem rgba(0,0,0,.15)
}

.navbar-toggler {
    margin-right: 6vw;
}

.logo-operandum {
    max-height: 115px;
    position: relative; // is it really worth relative
    left: 0px;
}

@media only screen and (min-width: 1025px) {
    .logo-operandum {
        max-height: 160px;
        left: 40px;
    }
}

@media only screen and (max-width: 1024px) {
    .navbar-toggler {
        background-color: var(--bs-light);
    }
}

@media only screen and (min-width: 1025px) {
    .logo-operandum {
        max-height: 175px;
        top: -2px;
        left: 9px;
    }
}

.linkedin {
    background-image: url(./assets/linkedin.png);
    background-size: contain;
    height: 36px;
    width: 36px;
    background-repeat: no-repeat;
}

.facebook {
    background-image: url(./assets/facebook.png);
    background-size: contain;
    height: 36px;
    width: 36px;
    background-repeat: no-repeat;
}

.facebook:hover {
    opacity: 0.7;
}

.linkedin:hover {
    opacity: 0.7;
}

.email:hover {
    opacity: 0.7;
}

.smartphone {
    background-image: url(./assets/smartphone.jpg);
    background-position: left;
    background-position-x: 0px;
    background-size: contain;
    height: 36px;
    background-repeat: no-repeat;
}
</style>
