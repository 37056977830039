import {
    createRouter,
    createWebHistory
} from 'vue-router'

import Home from '../views/Home.vue'

const routes = [
    {
        path      : '/',
        component : Home
    },
    {
        path      : '/over',
        component : () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path      : '/vacatures',
        component : () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue')
    },
    {
        path      : '/vacatures/:id',
        component : () => import(/* webpackChunkName: "jobarticle" */ '../views/JobArticle.vue')
    },
    // {
    //     path      : '/nieuws',
    //     component : () => import(/* webpackChunkName: "news" */ '../views/News.vue')
    // },
    // {
    //     path      : '/nieuws/:id',
    //     component : () => import(/* webpackChunkName: "newsarticle" */ '../views/NewsArticle.vue')
    // },
    {
        path      : '/:catchAll(.*)',
        component : () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
    }
]

const router = createRouter({
    linkActiveClass      : 'active',
    linkExactActiveClass : 'active',
    history              : createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            // Test if behavior: 'smooth' works
            return Object.assign({}, savedPosition, { behavior: 'smooth' })
        }

        return { x: 0, y: 0 }
    }
})

export default router
