import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

createApp(App, { /* props */ }).use(router).mount('#app')

if (!App.methods) App.methods = {}

window.isMobile = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <= 1024

// strange
// Would there be a way to watch window.location? With a Vue watcher?
router.afterEach((to, from) => {
    App.methods.setIsHome(to.path === '/')
})
