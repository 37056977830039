<template lang="pug">
router-link.link-light(
    to="/vacatures"
    style="text-decoration:none;"
)
    .container-fluid.background-image.d-flex.align-items-center.justify-content-center(
        id="go-to-vacancies-container"
    )
        button.btn.btn-lg.btn-outline-success.x-btn-background-light(
            id="go-to-vacancies-button"
            type="button"
        ) Ga naar Vacatures
.container-fluid.py-5.my-5.px-0.lh-lg
    p.m-lg-5.px-5
     | Werving en Selectie is de corebusiness van Operandum.
     | Als u op zoek bent naar vast of tijdelijk personeel dan bent u bij ons aan het juiste adres,
     | want bij ons staat een persoonlijke,
     | professionele en kwalitatief hoogwaardige dienstverlening centraal.
     | Wij richten ons op een samenwerking voor de lange termijn.
    p.m-lg-5.px-5
     | Dankzij een goed netwerk en jarenlange ervaring hebben wij al veel vacatures in de watersport,
     | jachtservice en jachtbouw naar volle tevredenheid mogen invullen.
     | Daarbij gaat het niet alleen om passie, maar vooral ook om een nuchtere kijk,
     | begrip en wederzijds vertrouwen.
     | Dat zijn dan ook onze uitgangspunten.
    p.m-lg-5.px-5
     | Natuurlijk zijn we er ook voor onze kandidaten,
     | want zonder hen kunnen we de vacatures immers niet invullen en daarom komen wij graag in
     | contact met geïnteresseerde professionals die een volgende stap in hun loopbaan overwegen.
     | Natuurlijk zijn ook de zogenaamde starters meer dan welkom om contact met ons op te nemen.
</template>

<script>
export default {
    name : 'Home'
}
</script>

<style lang="scss">
#go-to-vacancies-container:hover #go-to-vacancies-button {
    background-color: var(--bs-success);
    color: var(--bs-light);
}

.background-image {
    background-image: url(../assets/cover_comp_twice_cut.jpg);
    background-position-x: -233px;
    background-position-y: 15px;
    height: 355px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (min-width: 768px) {
    .background-image {
        background-image: url(../assets/cover_comp_twice.jpg);
        background-position-x: -280px;
        background-position-y: -68px;
    }
}

@media only screen and (min-width: 1025px) {
    .background-image {
        height: 620px;
        background-size: contain; // ? not cover?
        background-position-x: 0px;
        background-position-y: -1px
    }
}
</style>
