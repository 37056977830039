export const monthNames = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december'
]

export const formatDateToNL = (timestamp) => {
    const month = new Date(timestamp).getMonth()
    const day = new Date(timestamp).getDate()
    const year = new Date(timestamp).getFullYear()
    const formatted = `${day} ${monthNames[month]} ${year}`
    return formatted
}

export default { monthNames, formatDateToNL }
